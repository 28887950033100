<script>
import { reactive, ref, toRefs, watch } from "vue";
import { createBankAccounts, updateBankAccounts } from "./api";
import { Toasts } from "../../../utils/toast-alerts";

export default {
  name: 'bankAccountModal',
  props:{
    account:{
      type: Object
    },
  },
  setup(props, { emit })  {
    const { account } = toRefs(props)
    const loading = ref(false)
    const formDataBankAccount = reactive({
      gateway: "PAGGUE",
      gateway_data: {
        mp_access_token: '',
        client_key: '',
        client_secret: '',
      },
      type: "PIX",
      key: "----",
      cc: "----",
      agency: "----",
      name: "",
      main: false,
    });

    async function submitBankAccount(){
      try {
        const bankAccountToSave = { ...formDataBankAccount }
        let successMessage = 'Conta criada com sucesso!'
        if (!account.value) {
          await createBankAccounts(bankAccountToSave)
        } else {
          await updateBankAccounts(account.value.id, bankAccountToSave)
          successMessage = 'Conta atualizada com sucesso!'
        }
        Toasts('success', successMessage)
        const btnClose = document.querySelector("#form-btn-close")
        btnClose.click()

        emit('submit',)

      }catch (e) {
        Toasts('error', 'Falha ao criar a conta bancária')
      }
    }

    function cancelSubmit (){
      emit('submit')
    }

    watch(formDataBankAccount, (newVal) => {
      if (newVal.type === "PIX"){
        newVal.cc = undefined
        newVal.agency = undefined
        newVal.dv = undefined
      }else{
        newVal.key = undefined
      }
    })

    watch(account, (newVal) => {
      formDataBankAccount.type = newVal?.type || "PIX";
      formDataBankAccount.name = newVal?.name || "";
      formDataBankAccount.gateway = newVal?.gateway || "MP";
      formDataBankAccount.gateway_data = newVal?.gateway_data || {
        mp_access_token: '',
        client_key: '',
        client_secret: '',
      };
      formDataBankAccount.main = newVal?.main || false;
    });

    return {
      formDataBankAccount,
      submitBankAccount,
      cancelSubmit,
      loading,
    };
  },
  components: {

  },
};
</script>
<template>
  <div
      class="modal fade add-new-bank-account"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myExtraLargeModalLabel"
      aria-hidden="true"
  >
    <div
        class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="!account" >
            Nova conta
          </h5>
          <h5 class="modal-title" v-else>
            Editar conta
          </h5>
          <button
              type="button"
              class="btn-close"
              id="form-btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="cancelSubmit"
          ></button>
        </div>
        <form class="modal-body was-validated" :v-model="formDataBankAccount" @submit.prevent=" submitBankAccount">
          <div class="row">
            <div class="col-md-12">
              <div class="mb-6">
                <label
                    class="form-label mt-3"
                    for="AddBankAccount-Name"
                >Nome
                </label>
                <input
                    v-model="formDataBankAccount.name"
                    type="text"
                    class="form-control"
                    id="AddBankAccount-Name"
                    required
                />
                <div class="invalid-tooltip">
                  Informe um nome para a conta
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-6">
                <label class="form-label mt-3" for="AddOrder-Product"
                >Gateway</label
                >
                <select v-model="formDataBankAccount.gateway" class="form-select" id="AddOrder-Product" >
                  <option selected value="PAGGUE">Paggue</option>
                </select>
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-6" v-if="formDataBankAccount.gateway === 'MP'">
                <label class="form-label mt-3">Token Mercado Pago</label>
                <input
                    v-model="formDataBankAccount.gateway_data.mp_access_token"
                    type="text"
                    class="form-control"
                    placeholder=""
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-6" v-if="formDataBankAccount.gateway === 'PAGGUE'">
                <label class="form-label mt-3">Client key Paggue</label>
                <input
                    v-model="formDataBankAccount.gateway_data.client_key"
                    type="text"
                    class="form-control"
                    placeholder=""
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-6" v-if="formDataBankAccount.gateway === 'PAGGUE'">
                <label class="form-label mt-3">Client secret Paggue</label>
                <input
                    v-model="formDataBankAccount.gateway_data.client_secret"
                    type="text"
                    class="form-control"
                    placeholder=""
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-6" v-if="formDataBankAccount.type === 'BANK'">
                <label class="form-label mt-3">Conta corrente</label>
                <input
                    v-model="formDataBankAccount.cc"
                    type="text"
                    class="form-control"
                    placeholder=""
                    :required="formDataBankAccount.type === 'BANK'"
                />
                <div class="invalid-tooltip">
                  Informe o número da conta.
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-6" v-if="formDataBankAccount.type === 'BANK'">
                <label class="form-label mt-3">Agência</label>
                <input
                    v-model="formDataBankAccount.agency"
                    type="text"
                    class="form-control"
                    placeholder=""
                    :required="formDataBankAccount.type === 'BANK'"
                />
                <div class="invalid-tooltip">
                  Informe o número da agência.
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-6" v-if="formDataBankAccount.type === 'BANK'">
                <label class="form-label mt-3">Dígito verificador</label>
                <input
                    v-model="formDataBankAccount.dv"
                    type="text"
                    class="form-control"
                    placeholder=""
                    :required="formDataBankAccount.type === 'BANK'"
                />
                <div class="invalid-tooltip">
                  Informe o dígito verificador.
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div>
                <h5 class="font-size-14 mb-3 mt-3">Conta principal</h5>
                <div class="form-check form-switch mb-3">
                  <input
                      v-model="formDataBankAccount.main"
                      class="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      checked>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12 text-end">
              <button
                  type="button"
                  class="btn btn-danger me-1"
                  data-bs-dismiss="modal"
                  @click="cancelSubmit"
              >
                <i class="bx bx-x me-1"></i> Cancelar
              </button>
              <button
                  class="btn btn-success"
                  data-bs-target="#success-btn"
                  id="btn-save-event"
                  type="submit"
              >
                <i class="bx bx-check me-1"></i> Salvar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!--  successfully modal  -->
  <!--    <div-->
  <!--      id="success-btn"-->
  <!--      class="modal fade"-->
  <!--      tabindex="-1"-->
  <!--      aria-hidden="true"-->
  <!--      data-bs-scroll="true"-->
  <!--    >-->
  <!--      <div class="modal-dialog modal-dialog-centered">-->
  <!--        <div class="modal-content">-->
  <!--          <div class="modal-body">-->
  <!--            <div class="text-center">-->
  <!--              <i class="bx bx-check-circle display-1 text-success"></i>-->
  <!--              <h3 class="mt-3">Order Completed Successfully</h3>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
</template>
