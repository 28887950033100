import { axios } from '../../../axios'

export const getBankAccounts = (params = {}) => {
  return axios.get('/bank-accounts', { params }).then(({ data }) => data)
}

export const removeBankAccounts = (id = {}) => {
  return axios.delete(`/bank-accounts/${id}`).then(({ data }) => data)
}

export const createBankAccounts = (params = {}) => {
  return axios.post('/bank-accounts',  params ).then(({ data }) => data)
}

export const updateBankAccounts = (id , params = {}) => {
  return axios.put(`/bank-accounts/${id}`,  params ).then(({ data }) => data)
}