<script>
import Layout from "../../layouts/main";
import { computed, onMounted, ref, reactive } from "vue";
import { getBankAccounts, removeBankAccounts } from "./api";
import Swal from "sweetalert2";
import BankAccountModal from "./BankAccountModal";
import { debounce } from "lodash";
import AppPagination from "../../../components/app-pagination";
import { isFeatureEnabled } from '@/feature-check'
import { Toasts } from "../../../utils/toast-alerts";

export default {
  setup() {
    let loading = ref(false);
    let bankAccountList = ref([]);
    const totalBankAccounts = ref(0)
    const page = ref(1)
    const pages = ref(0)
    let removing = ref(null)
    let bankAccountData = ref(null)

    const filterBankAccount = reactive({
      search: "",
      limit: 10,
      page: 1,
    })

    const handleSearch = debounce(async (e) => {
      filterBankAccount.title = e.target.value;
      await fetchBankAccounts(filterBankAccount);
    }, 500);

    async function fetchBankAccounts() {
      loading.value = true;
      try {
        const response = await getBankAccounts({
          search: filterBankAccount.search,
          page: page.value
        } );
        bankAccountList.value = response.data
        totalBankAccounts.value = response.total
        pages.value = response.last_page

      }catch (error) {
        Toasts('error', 'Falha ao carregar as contas bancárias')
      } finally {
        loading.value = false;
      }
    }

    async function onSubmit() {
      await fetchBankAccounts()
      bankAccountData.value = null
    }

    async function deleteBankAccount(account){
      removing.value = account
      try {
        await removeBankAccounts(account)
        fetchBankAccounts()
      } catch (e) {
        Toasts('error', 'Falha ao excluir a conta bancária')
      }
      removing.value = null
    }

    function updateAccount(account){
      bankAccountData.value = account
    }

    function  confirm(accountId) {
      Swal.fire({
        title: "Atenção!",
        text: "Tem certeza que deseja excluir esta conta bancária?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Excluir"
      }).then(result => {
        if (result.value) {
          Swal.fire("Excluída!", "A conta bancária foi excluido com sucesso!", "success", deleteBankAccount(accountId));
        }
      });
    }

    const allowCreate = computed(() => {
      return isFeatureEnabled()
    })

    onMounted(() => {
      fetchBankAccounts();
    });

    return {
      loading,
      bankAccountList,
      totalBankAccounts,
      onMounted,
      fetchBankAccounts,
      deleteBankAccount,
      removing,
      confirm,
      onSubmit,
      updateAccount,
      bankAccountData,
      filterBankAccount,
      handleSearch,
      page,
      pages,
      allowCreate
    };
  },
  components: {
    AppPagination,
    Layout,
    BankAccountModal
  },
};

</script>

<template>
  <Layout :pagetitle="'Minhas contas bancárias'">
    <div class="card">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div>
            <h5 class="card-header bg-transparent border-bottom text-uppercase">
              Contas bancárias <span class="text-muted fw-normal ms-2">({{ totalBankAccounts }})</span>
            </h5>
          </div>
        </div>
        <div class="col-md-6">
          <div
            class="
              card-header
              bg-transparent
              border-bottom
              d-flex
              flex-wrap
              justify-content-end
              mb-3"
          >
            <div>
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <router-link
                    class="btn btn-primary waves-effect waves-light"
                    v-if="allowCreate"
                    :to="{
                      name: 'wizard_stages',
                      params: {
                        stage: 'bank-account'
                      }
                    }"
                  >
                    <i class="bx bx-plus me-1"></i>
                    Nova conta
                  </router-link>
                  <upgrade-trigger
                    v-else
                    title="Nova conta"
                    class="btn btn-primary waves-effect waves-light"
                    icon="bx bx-plus me-1"
                    style="width: 100%"
                  />
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
      <div class="row">
        <div class="col">
          <h5 class="card-header pb-3">
            <div class="position-relative">
              <i
                class="search-icon"
                data-eva="search-outline"
                data-eva-height="26"
                data-eva-width="26"
              >
              </i>
              <input
                v-model="filterBankAccount.search"
                type="text"
                style="min-width: 350px; max-width: 500px;"
                class="form-control"
                placeholder="Pesquisar uma conta bancária"
                @input="handleSearch"
            >
            </div>
          </h5>
        </div>
      </div>
      <!-- end row -->
      <div class="row">
        <div class="col-lg-12">
          <div class="">
            <div class="table-responsive">
              <table
                  class="table project-list-table table-nowrap align-middle table-borderless"
              >
                <thead class="table-light">
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Tipo de conta</th>
                    <th scope="col">Verificação</th>
                    <th scope="col" style="text-align: right;">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="account in bankAccountList"
                    :key="account.id"
                  >
                    <td>
                      <a class="text-body">{{ account.name }} ({{ account.gateway }})</a>
                    </td>
                    <td>{{ account.type }}</td>
                    <td>
                      <span v-if="account.account_verified_at">
                        OK: {{ $d(account.account_verified_at, 'info') }}
                      </span>
                      <span v-else>
                        Aguardando
                      </span>
                    </td>
                    
                    <td style="text-align: right;">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                          <router-link
                            class="btn btn-primary"
                            :to="{
                              name: 'wizard_stages',
                              params: {
                                stage: 'bank-account'
                              },
                              query: {
                                id: account.id
                              }
                            }"
                          >
                            <i class="bx bx-pencil font-size-18"></i>
                          </router-link>
                        </li>
                        <li class="list-inline-item">
                          <a
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Delete"
                              class="p-2 text-white btn btn-danger"
                              @click="confirm(account.id)"
                              :disabled="removing === account.id"
                          ><i class="bx bx-trash-alt font-size-18"></i
                          ></a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <app-pagination
                  class="pagination"
                  v-model="page"
                  :pages="pages"
                  :range-size="1"
                  @update:modelValue="fetchBankAccounts"
              />
              <bank-account-modal
                @submit="onSubmit()"
                :account = bankAccountData
              >
              </bank-account-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>